<template>

    <export-excel  
        :data="json_data"
        :fields="json_fields"
        worksheet="My Worksheet"
        :name="new Date(Date.now()).toLocaleDateString('en-GB') +' سجل المشتركين'"
    >
    <b-button variant="primary" class="float-right">
        تصدير إلى Excel
    </b-button>
</export-excel>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState({
            json_data: state => state.store.usersList
        })
    },
    data: () => ({
        json_fields: {
            'اسم الزبون': 'name',
            'رقم الهاتف': 'phoneNumber',
            'العنوان': 'area',
            'التاريخ': 'createdDate',
            'WhatsApp': 'added',
            

        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
    })
}
</script>